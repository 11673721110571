import React, { useEffect } from "react"
import { useState } from "react"
import styled from "styled-components"
import { colors } from "../styles/colors"
import StoresCarousel from "../components/landing/stores-carousel"
import SaleCarousel from "../components/landing/sale-carousel"
import RecommendedCarousel from "components/landing/recommended-carousel"
import CategoriesCarousel from "components/landing/categories-carousel"
import LeaderboardSection from "components/landing/leaderboard-section"
import Spacer from "components/spacer"
import SearchModal from "components/landing/search-modal"
import SearchButton from "components/landing/search-button"
import { Flex } from "components/layout/shared"
import Header from "components/shared/header"
import { getLandingPageSnapshot } from "lib/firebase"
import SEO from "components/seo"
import ContactSection from "components/landing/contact-section"
import FeaturedCategoriesCarousel from "components/landing/featured-categories-carousel"
import { fetcher } from "network"
import useSWR from "swr"
import Head from "next/head"

const Container = styled.div`
    background: #F7F7F7;
`;
const SearchContainer = styled(Flex)`
    margin: 0 auto;
    max-width: 1200px;
    padding: 10px;
    justify-content: flex-end;

    @media (max-width: 550px) {
        justify-content: center;
    }

`;

const IndexPage = ({staticData}) => {
    
    const { data: serverData, error } = useSWR(`/api/landing/landing-page-data`, fetcher)
    const [searchModalOpen, setSearchModalOpen] = useState(false)

    const shuffleArray = array => {
        const oldArray = [...array ?? []]
        for (let i = oldArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1))
            ;[oldArray[i], oldArray[j]] = [oldArray[j], oldArray[i]]
        }
        return oldArray
    }

	const {
        categories,
        featuredCategories,
        leaderboardCategories,
        newOnUnqueue,
        recommendedForYou,
    }= staticData || []
	
    const {
        onSaleNow,
        popularStores,
    }= serverData || []
    

    return (
        <Container>
            <Header />
            <Head>
                <meta name="apple-itunes-app" content="app-id=1518497712" />
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" />
            </Head>
            <SEO title="Shop" description="Choices, details, convenience. Shop food, clothing, plants, electronics, and more – all in one place." />
            {searchModalOpen &&
                <SearchModal
                    open={searchModalOpen}
                    setOpen={setSearchModalOpen}
                />
            }
            <div style={{zIndex: 3, position: 'sticky', top: 51, background: 'white', borderBottom: '1px solid #ddd'}}>
                <SearchContainer style={{margin: '0 auto', maxWidth: 1200, padding: 10}} direction="row" align="center">
                    <SearchButton clickHandler={() => setSearchModalOpen(true)} />
                </SearchContainer>
            </div>
            <section style={{background: colors.base}}>
                <LeaderboardSection leaderboardCategories={leaderboardCategories} />
            </section>
            {/* <Spacer size={30}/> */}
            <RecommendedCarousel
                data={shuffleArray(recommendedForYou)}
                title="Recommended For You"    
                useHeader
            />
            <Spacer size={30}/>
            <StoresCarousel data={newOnUnqueue} title="New On Unqueue" />
            <Spacer size={30}/>
            <SaleCarousel data={shuffleArray(onSaleNow)} title="On Sale Now" />
            <StoresCarousel data={shuffleArray(popularStores)} title="Highlights" />
            <FeaturedCategoriesCarousel featuredCategories={shuffleArray(featuredCategories)} />
            <CategoriesCarousel data={categories} title="All Stores" />
            <Spacer size={30}/>
            <ContactSection />
        </Container>
    )
}

export default IndexPage

export async function getStaticProps(){

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({"data":{}});
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    const result=await fetch("https://us-central1-pickup-things.cloudfunctions.net/getLandingPageSnapshot", 
    requestOptions)
      .then(response => response.json())
      .then(result => {return (result)})
      .catch(error => console.log('error', error));
      return{props:{staticData: result.result}}

    // const res = await getLandingPageSnapshot({data: ""})
    // console.log(res.data)
    // return {
    //     props: {
    //         staticData: res.data
    //     }
    // }
}
